export function useDialer() {
  const callerIds = useState<CallerId[]>('dialer_caller_ids', () => [])
  const quickCall = useState<DialInput|null>('dialer_quick_call', () => null)

  const getCallerIds = async () : Promise<CallerId[]> => {
    const { $api } = useNuxtApp()
    return await $api<CallerId[]>('/crm/phone-numbers/caller-ids/')
      .then(response => {
        callerIds.value = response
        return callerIds.value
      })
      .catch(() => {
        console.info("Unable to retrieve caller IDs.")
      })
  }

  return { callerIds, getCallerIds, quickCall }
}
